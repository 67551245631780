const ThankYouFooter = () => { 
    return (
        <>
           <div className="connect-sec">
                <div className="cont-txt"><h4>Let's Connect! :</h4><span className="line-gr"></span></div>
                <div className="cont-sosial-links">
                    <ul>
                        <li><a href="#"><i className='bx bxl-facebook' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-instagram-alt' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-linkedin-square' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-youtube' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-twitter' ></i></a></li>
                        <li><a href="#"><i className='bx bxl-pinterest' ></i></a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ThankYouFooter