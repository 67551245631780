import React from 'react';
import Nav from '../../components/layouts/nav';
import Footer from '../../components/layouts/footer';
import { URLS } from '../../_config';
import { Link } from 'react-router-dom';

const AboutUs: React.FC = () => {
    return (
        <div className='bg-white'>
            <Nav />

            <div className="about-banner inner-page-banner-size mt-105">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breathcrumb" data-aos="fade-up">
                                <ul>
                                    <li><Link to={URLS.HOME} className="de-select">Home</Link></li>
                                    <li><i className='bx bx-chevron-right' ></i></li>
                                    <li>About</li>
                                </ul>
                            </div>
                            <div className="about-text mt-40">
                                <h2 data-aos="fade-up">We’re <span>changing</span> the way the<br />
                                    world presents.</h2>
                                <p data-aos="fade-up">Our mission is to make it easy for anyone to turn their ideas into<br />visual stories that win.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="body-part warm-bg">
                <div className="container">
                    <div className="sub-heading-inner text-center mb-70">
                        <h2 data-aos="fade-up" >A little <span>guidance,</span>achieve almost<br />any <span>business</span> goal</h2>
                    </div>
                    <div className="row position-relative">
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="business-text" data-aos="fade-right">
                                <div className="business-text-img"><img src="/images/img/b1.svg" alt='' /></div>
                                <div className="business-text-inner">
                                    <h3>Build your business plan faster and easier with AI</h3>
                                    <p>Say goodbye to writer’s block. Ensure your business plan is the best it can be with the AI-Powered PlanCraft Assistant.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-12"><div className="guide-img" data-aos="zoom-in"><img src="/images/img/man-obj.png" alt='' /></div></div>
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="business-text" data-aos="fade-left">
                                <div className="business-text-img"><img src="/images/img/b2.svg" alt='' /></div>
                                <div className="business-text-inner">
                                    <h3>Get inspired by 550+ sample business plans</h3>
                                    <p>Browse hundreds of sample business plans that cover every industry imaginable. This helps you visualize what your completed plan should look like.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="history-sec">
                <div className="container">
                    <div className="row align-items-center mob-flip">
                        <div className="col-lg-8 col-md-8 col-12 mb-50 pe-5">
                            <div className="section-para">
                                <h2 data-aos="fade-up">Our <span>History</span></h2>
                                <p data-aos="fade-up">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    <br />
                                    <br />
                                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-12 mb-50">
                            <div className="section-para-img" data-aos="fade-left">
                                <img src="/images/img/our-history.png" alt='' />
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4 col-12 ">
                            <div className="section-para-img" data-aos="fade-right">
                                <img src="/images/img/our-team.png" alt='' />
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-12 ps-5">
                            <div className="section-para">
                                <h2 data-aos="fade-up">Our <span>Team</span></h2>
                                <p data-aos="fade-up">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    <br />
                                    <br />
                                    It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="get-started-color">
                <div className="container">
                    <div className="get-started">
                        <h2 data-aos="fade-up">Get started FREE with PlanCrafter today</h2>
                        <h3 data-aos="fade-up">and take the first steps to planning your business’ future development</h3>
                        <Link to={URLS.SUBSCRIPTION} className="master-orng-btn black-btn" data-aos="zoom-in">Start Your Business Plan</Link>
                        {/* <button type="button" className="master-orng-btn black-btn" data-aos="zoom-in">Start Your Business Plan</button> */}
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AboutUs;