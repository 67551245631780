import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { API_URL, URLS } from "../../_config";
import { useToken, useUser } from "../../_common/hooks/authHook";
import toast from "react-hot-toast"; 
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({ propData }: any) => {
  const stripe: any = useStripe();
  const elements: any = useElements();
  const userData: any = useUser();
  const tokenData: any = useToken();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate: any = useNavigate();

  const submitPayment = () => {
    return new Promise((resolve, reject) => {
      if (!stripe || !elements) {
        return;
      }
      setProcessing(true);

      const cardElement = elements.getElement(CardElement);
      const stripeData = stripe.confirmCardPayment(propData?.clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: userData?.full_name,
            email: userData?.email
          },
        }
      }).then(function (result: any) {
        // Handle result.error or result.paymentIntent 
        if (result.error) {
          setError(result.error.message);
          setProcessing(false);
          reject(result.error.message);
        } else {
          if (result.paymentIntent.status === "succeeded") {
            setProcessing(false);
            setError(null);
            resolve('Payment successful');
            // console.log('PaymentIntent==============', result.paymentIntent);

            let params = {
              status: result.paymentIntent.status,
              proposal_id: propData?.paymentProposalId,
              amount: result.paymentIntent.amount,
              currency: result.paymentIntent.currency,
              transaction_id: result.paymentIntent.id,
              payment_method: result.paymentIntent.payment_method,
              payment_method_types: result.paymentIntent.payment_method_types,
              transaction_date: new Date().toISOString(),
            }
            fetch(API_URL.PAYMENTS.STRIPE_PAYMENT_STORE, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${tokenData}` },
              body: JSON.stringify(params),
            })
              .then(response => response.json())
              .then(resp => {
                if (resp?.status == 200) { // Success 
                  resolve(resp.message);
                  // console.log('client_secret==========', resp);
                  // window.location.reload(); // Refresh the page
                  navigate(URLS.ACCOUNT.PAYMENT.THANK_YOU)
                } else { // Error
                  setError(resp?.message);
                  reject(resp.message);
                  setProcessing(false);
                }
              })
              .catch(error => { // Error
                reject(error);
                setError(error);
                setProcessing(false);
              });
          }
        }
      });
    })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    toast.promise(
      submitPayment(), {
      loading: 'Please wait...',
      success: (msg: any) => {
        return <b>{msg}</b>
      },
      error: (msg: any) => { return <b>{msg}</b> },
    });

  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#333', // Dark gray text color for readability
        fontFamily: 'Poppins, Arial, sans-serif', // Modern, clean font
        fontSmoothing: 'antialiased',
        fontSize: '18px', // Standard readable font size
        '::placeholder': {
          color: '#999', // Light gray for placeholders
          fontStyle: 'italic', // Add a bit of style to placeholders
        },
        backgroundColor: '#f9f9f9', // Soft background for a cleaner look
        border: '1px solid #e2e2e2', // Light border for input box
        borderRadius: '8px', // Rounded corners for the input box
        padding: '12px 14px', // Padding inside the input field for better spacing
      },
      invalid: {
        color: '#fa755a', // Red color for invalid input
        iconColor: '#fa755a', // Red color for the card icon when input is invalid
      },
    },
    hidePostalCode: true, // Optionally hide postal code input if not needed
  };
  const formStyles = {
    maxWidth: '600px',
    margin: '15px auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid #afb6ff',
  };

  const labelStyles = {
    display: 'block',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: '#333',
  };

  const inputWrapperStyles = {
    border: '1px solid #e2e2e2',
    borderRadius: '8px',
    padding: '10px',
    backgroundColor: '#f9f9f9',
  };

  const buttonStyles = {
    backgroundColor: '#6772E5',
    color: '#fff',
    padding: '12px 20px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  };

  const errorStyles = {
    color: 'red',
    marginTop: '10px',
    fontSize: '14px',
  };

  return (
    <form onSubmit={handleSubmit} className="checkout-form" style={formStyles}>

      <div style={{ marginBottom: '20px' }}>
        <label htmlFor="card-element" style={labelStyles}>
          Credit or Debit Card
        </label>
        <div style={inputWrapperStyles}>
          <CardElement id="card-element" className="card-element"
            options={CARD_ELEMENT_OPTIONS}
          />
        </div>
        {error && <p style={errorStyles}>{error}</p>}
      </div>

      {/* <button type="submit" className="stripePay submit-button" style={buttonStyles} disabled={!stripe || processing}>
        {processing ? 'Processing...' : `Pay Now`}
      </button> */}
      <button type="submit" style={buttonStyles} disabled={!stripe}>
        Pay ${(propData.paymentAmount / 100).toFixed(2)}
      </button>
    </form>
  );
};

export default CheckoutForm;
