import { useEffect, useState } from "react";
import Footer from "../../components/layouts/footer";
import { API_URL, URLS } from "../../_config";
import { useToken } from "../../_common/hooks/authHook";
import { proposalStatus, replaceUrlParams, showDate } from "../../_common/functions";
import Nav from "../../components/layouts/nav";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../components/icon";

const TicketList = () => {
    const [tickets, setTickets] = useState<any>(null);
    const navigate: any = useNavigate();
    const tokenData: any = useToken();

    const getTicketList = async () => {
        try {
            const response = await fetch(API_URL.ACCOUNT.TICKET.LIST, {
                headers: { Authorization: `Bearer ${tokenData}` }
            });
            const resp = await response.json();
            setTickets(resp.data)
            if (resp.message == 'Unautheticated.') {
                navigate(URLS.LOGOUT);
            }
        } catch (error) {
            console.log('Error occurred while making the API request.', error);
            // Handle error
            setTickets([])
        }
    }

    useEffect(() => {
        getTicketList();
    }, [])

    return (
        <>
            <Nav />

            <div className="body-part gray-bg mt-105">
                <div className="container text-center">
                    <h2 className="page-title">My <span>Tickets</span></h2>
                    <p className="tag-line">Manage your Tickets</p>


                    <div className="profile-box">
                        <div className="profile-box-title align-items-center">
                            <div className="profile-icon"><Icon name="ticket" /></div>
                            <div className="align-items-center d-flex justify-content-between profile-text">
                                <span>My Tickets</span>
                                <Link to={URLS.ACCOUNT.TICKET.CREATE} className="ms-auto"><button type="button" className="master-orng-btn">Create Ticket</button></Link>
                            </div>
                        </div>
                        <div className="profile-box-data">
                            <div className="table-responsive">
                                <table className="table table-striped plan-craft-table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Ticket No.</th>
                                            <th>Subject</th>
                                            <th>Created Date</th>
                                            <th>Updated Date</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets && tickets.map((ticketData: any, index: number) => {
                                            return (
                                                <tr key={ticketData?.id}>
                                                    <td>{index + 1}</td>
                                                    <td> {ticketData.ticket_no} </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <span>
                                                                {ticketData?.subject}
                                                            </span>
                                                            {
                                                                ticketData?.attachments && ticketData?.attachments.map((attachment: any, index: number) => {
                                                                    return (
                                                                        <Link to={attachment.original} target='_blank' className="download_icon ms-3" style={{ width: '25px', height: '25px' }} key={`attachment.original${index}`} title="Download File">
                                                                            <Icon name="download-file-solid" size={15} />
                                                                        </Link>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>{showDate(ticketData.created_at)}</td>
                                                    <td>{showDate(ticketData.updated_at)}</td>
                                                    <td><span className={`${ticketData.status_badge} text-white`}>{ticketData.status_text}</span></td>
                                                    <td className="actions">

                                                        <Link to={replaceUrlParams(URLS.ACCOUNT.TICKET.REPLY, { ticketId: ticketData.id })}>
                                                            {ticketData.status_text !== 'Closed' ?
                                                                <span title="View & Reply"><Icon name="reply-all" /></span> :
                                                                <span title="View Ticket Details"><Icon name="eye" /></span>
                                                            }
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        <tr>
                                            {
                                                tickets && tickets.length === 0 && <td colSpan={7} className="text-center">
                                                    <p className="mt-3 text-red-500">No data found!</p>
                                                </td>
                                            }
                                            {
                                                !tickets && <td colSpan={7} className="text-center"><p>Loading...</p></td>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>

    )
}

export default TicketList; 